export const BAR_MAP = {
  vertical: {
    max: 'maxHeight',
    margin: 'marginTop',
    direction: 'top',
    scrollSize: 'scrollHeight',
    size: 'height',
    offset: 'offsetHeight',
    '38': 'up',
    '40': 'down'
  },
  horizontal: {
    max: 'maxWidth',
    margin: 'marginLeft',
    direction: 'left',
    scrollSize: 'scrollWidth',
    size: 'width',
    offset: 'offsetWidth',
    '37': 'left',
    '39': 'right'
  }
};
