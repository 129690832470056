<template>
  <div
    class="pop-scroll"
    @mouseenter="overScroll = true"
    @mouseleave="overScroll = false"
  >
    <div class="content-wrapper" :style="contentWrapperStyle">
      <div ref="content" class="pop-scroll-content" :style="contentStyle">
        <slot />
      </div>
    </div>
    <div
      v-show="showBarFlag"
      ref="scrollBox"
      class="pop-scroll-box"
      :class="{ horizontal: horizontal }"
      :style="scrollBoxStyle"
    >
      <div ref="scrollbar" class="pop-scrollbar" :style="scrollBarStyle" />
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { BAR_MAP } from './util';
export default {
  props: {
    id: {
      type: String,
      default: 'defaultId'
    },
    // 出现滚动条的高度，当大于0时有效(总开关)
    scrollHeight: {
      type: Number,
      default: 0
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    scrollSpeed: {
      type: Number,
      default: 10
    }
  },
  data () {
    return {
      // 滚动速度
      speed: 10,
      // 滑块距离顶部的距离
      barTop: 0,
      // 滚动内容距离顶部的距离
      contentTop: 0,
      // 滚动内容本身的高度
      contentHeight: 0,
      // 是否显示滑块
      showBarFlag: false,
      // 是否hover在当前scroll内容上
      overScroll: false,
      // 鼠标上次位置(拖拽时使用)
      mouseLastPosition: []
    };
  },
  computed: {
    bar () {
      return BAR_MAP[this.horizontal ? 'horizontal' : 'vertical'];
    },
    contentWrapperStyle () {
      let style = { overflow: this.showBarFlag ? 'hidden' : 'visible' };
      style[this.bar.max] = this.scrollHeight ? this.scrollHeight + 20 +'px' : '100%';
      if (!this.horizontal) {
        style.float = 'left';
      }
      return style;
    },
    contentStyle () {
      let style = {};
      style[this.bar.margin] = this.contentTop === 0 ? this.contentTop : (this.contentTop + 'px');
      return style;
    },
    scrollBoxStyle () {
      let style = {};
      style[this.bar.size] = this.scrollHeight + 'px';
      return style;
    },
    scrollBarStyle () {
      let style = {};
      style[this.bar.direction] = this.barTop === 0 ? this.barTop : (this.barTop + 'px');
      return style;
    },
    contentEl () {
      return this.$refs.content;
    },
    scrlBarEl () {
      return this.$refs.scrollbar;
    },
    scrlBoxEl () {
      return this.$refs.scrollBox;
    }
  },
  watch: {
    scrollHeight () {
      this.$nextTick(() => {
        this.init();
      });
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.init();
    });
    this.$emit('ready');
  },
  updated () {
    // content变化时从新计算
    if (this.contentHeight !== this.getContentSize()) {
      this.$nextTick(() => {
        this.init();
      });
    }
  },
  methods: {
    init () {
      if (!this.scrollHeight) {
        return;
      }
      let self = this;
      self.speed = self.scrollSpeed;
      self.contentHeight = self.getContentSize();
      if (self.contentHeight > 0) {
        self.showBarFlag = self.contentHeight > self.scrollHeight;
        self.resetMove();
        if (self.showBarFlag) {
          self.$refs.scrollbar.style[self.bar.size] = self.scrollHeight / self.contentHeight * self.scrollHeight + 'px';

          self.$refs.scrollbar.onmousedown = null;
          self.$refs.scrollbar.onmousedown = (event) => {
            event.preventDefault();
            self.mouseLastPosition = [event.clientX, event.clientY];
            self.drag.call(this);
          };
          self.wheel();
          self.key();
        }
      } else {
        setTimeout(function () {
          self.init();
        }, 200);
      }
    },
    getContentSize () { // 获取内容高度/宽度
      try {
        return this.$refs.content ? this.$refs.content.children[0][this.bar.scrollSize] : '';
      } catch (error) {
        return '';
      }
    },
    getScrollbarSize () { // 获取滚动条高度/宽度
      return this.$refs.scrollbar[this.bar.offset];
    },
    toTop () {
      this.contentTop = 0;
      this.barTop = 0;
    },
    toBottom () {
      let self = this;
      if (!self.showBarFlag) {
        return;
      }
      // 滚动到底部
      if (self.contentHeight - self.scrollHeight > 0) {
        self.contentTop = -(self.contentHeight - self.scrollHeight);
      } else {
        self.contentTop = 0;
      }
      // 滑块滚动到底
      if (self.scrollHeight - self.getScrollbarSize() > 0) {
        self.barTop = self.scrollHeight - self.getScrollbarSize();
      } else if (self.barTop < 0) {
        self.barTop = 0;
      }
    },
    resetMove () {
      if (this.barTop > this.scrollHeight - this.getScrollbarSize()) {
        // 修改内容使滚动条和内容溢出（超出最大值）时，需要重新定位滚动条和内容
        this.barTop = this.scrollHeight - this.getScrollbarSize();
        this.contentTop = -((this.barTop / this.scrollHeight) * this.contentHeight);
      }
      if (!this.showBarFlag) {
        // 修改内容使滚动条不满足使用条件时，滚动条消失，滚动条和内容的位移要重置为0
        this.toTop();
      }
    },
    drag () {
      let self = this;
      document.onmousemove = (event) => {
        let x2 = event.clientX;
        let y2 = event.clientY;
        let x = x2 - self.mouseLastPosition[0];
        let y = y2 - self.mouseLastPosition[1];
        self.mouseLastPosition = [x2, y2];

        // 计算移动距离
        let topValue = self.barTop;
        let scrollbarHeight = self.$refs.scrollbar[self.bar.offset];
        let maxHeight = self.scrollHeight - scrollbarHeight;
        topValue += (self.horizontal ? x : y);
        topValue = topValue <= 0 ? 0 : topValue;
        topValue = topValue >= maxHeight ? maxHeight : topValue;

        self.barTop = topValue;
        self.contentTop = -((topValue / self.scrollHeight) * self.contentHeight);

        // 横向边界处理
        if (self.horizontal === false && Math.abs(x) > 100 && self.$refs.scrollbar) {
          self.barTop = self.$refs.scrollbar.offsetTop;// 记录变化后的位置
          document.onmousemove = null;
        } else if (self.horizontal === true && Math.abs(y) > 100 && self.$refs.scrollbar) {
          self.barTop = self.$refs.scrollbar.offsetLeft;// 记录变化后的位置
          document.onmousemove = null;
        }
      };
      document.onmouseup = (event) => {
        document.onmousemove = null;
        if (self.horizontal === false && self.$refs.scrollbar) {
          self.barTop = self.$refs.scrollbar.offsetTop;// 记录变化后的位置
        } else if (self.horizontal === true && self.$refs.scrollbar) {
          self.barTop = self.$refs.scrollbar.offsetLeft;// 记录变化后的位置
        }
      };
    },
    wheel () {
      // 鼠标滚轮事件
      if (this.horizontal) {
        return;
      }
      let self = this;
      // chrome--event.wheelDelta, >0为鼠标滚轮向上滚动，<0为向下滚动
      self.$refs.content.onmousewheel = (event) => {
        let oEvent = event || window.event;
        oEvent.preventDefault();
        oEvent.wheelDelta > 0 ? self.up() : self.down();
      };

      // firefox--event.detail,>0为鼠标向下滚动，<0为向上滚动
      document.addEventListener('DOMMouseScroll', (event) => {
        let oEvent = event || window.event;
        if (self.overScroll) {
          oEvent.preventDefault();
          oEvent.detail > 0 ? self.down() : self.up();
        }
      });
    },
    key () {
      // 键盘事件
      let self = this;
      document.addEventListener('keydown', (event) => {
        if (!self.overScroll) {
          return;
        }
        let oEvent = event || window.event;
        const name = self.bar[oEvent.keyCode];
        if (name) {
          oEvent.preventDefault();
          self[name]();
        }
      });
    },
    left () {
      this.barTop = this.barTop - this.speed <= 0 ? 0 : this.barTop - this.speed;
      this.contentTop = -((this.barTop / this.scrollHeight) * this.contentHeight);
    },
    right () {
      let maxHeight = this.scrollHeight - this.getScrollbarSize();
      if (this.barTop + this.speed >= maxHeight) {
        this.barTop = maxHeight;
      } else {
        this.barTop += this.speed;
      }
      this.contentTop = -((this.barTop / this.scrollHeight) * this.contentHeight);
    },
    up () {
      this.barTop -= this.speed;
      this.barTop = this.barTop <= 0 ? 0 : this.barTop;
      let scrollBoxHeight = this.$refs.scrollBox.clientHeight;
      let scrollcontentHeight = this.$refs.content.offsetHeight;

      this.contentTop = -((this.barTop / scrollBoxHeight) * scrollcontentHeight);
    },
    down () {
      let scrollBoxHeight = this.$refs.scrollBox.clientHeight;
      let scrollbarHeight = this.$refs.scrollbar.offsetHeight;
      let scrollcontentHeight = this.$refs.content.offsetHeight;

      this.barTop += this.speed;

      if (this.barTop >= scrollBoxHeight - scrollbarHeight) {
        this.barTop = scrollBoxHeight - scrollbarHeight;
      }
      this.contentTop = -((this.barTop / scrollBoxHeight) * scrollcontentHeight);
    }
  }
};
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
.pop-scroll
    .content-wrapper
        max-height 412px
        .pop-scroll-content
          margin-top 0
          padding-bottom 30px
          ul
            padding 0
    .pop-scroll-box
        max-height 450px;
        position relative
        // width 10px
        height 80px
        top 0
        left calc(100% - 7px)
        right 0
        bottom 0
        // background-color #fafafa
        &.horizontal
            float none
            top auto
            left 0
            right 0
            bottom 0
            width auto
            height 10px
        .pop-scrollbar
            position absolute
            top 0
            width 4px
            left 3px
            background-color #E1E1E3
            border-radius 9px
        &.horizontal .pop-scrollbar
            top auto
            left 0
            right auto
            bottom 3px
            height 4px
</style>
